<template>
  <section>
    <ConteudoAplicado />
  </section>
</template>


<script>
import ConteudoAplicado from "@/components/conteudo_aplicado/index.vue"
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    ConteudoAplicado
  },
  data () {
    return {
     
    }
  },
  async beforeMount() {
   
  },
  methods:{
  },
})
</script>


